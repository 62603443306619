.SocialMedia {
  display: flex;
  gap: clamp(4px, calc(8vw / 7.68), 8px);
  a {
    width: 44px;
    height: 44px;
    display: grid;
    place-items: center;
    position: relative;
    z-index: 2;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: var(--primary-400, #ffd7e4);
      transform: scale(0);
      opacity: 0.62;
      transition: transform 0.3s var(--easing), opacity 0.3s;
    }
    &:hover,
    &:focus-visible {
      outline: none;
      &::before {
        content: '';
        transform: scale(1);
      }
    }
    &:active {
      &::before {
        opacity: 1;
      }
    }
  }
}
